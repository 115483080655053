<template>
  <b-modal id="modal-detail-forum" size="md" hide-footer>
    <!-- custom modal title -->
    <template #modal-title>
      <div class="d-flex align-items-center">
        <feather-icon size="20" icon="InfoIcon" class="mr-05" />
        <span class="fs-18"
          >Detail Log Forum
          <span v-if="data">
            <strong> "{{ data.scraper_name }}" </strong>
          </span>
        </span>
      </div>
    </template>
    <loader v-if="is_loading" />
    <div v-else class="scroller mb-2" style="max-height: 25rem">
      <div v-if="typeof data.error_message === 'string'">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2 d-flex">
                Error <span class="ml-auto">:</span>
              </div>
              <div class="col-md-10">{{ data.error_message }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="(item, index) in data.error_message"
          :key="index"
          class="card"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-md-2 d-flex pr-0">
                URL <span class="ml-auto">:</span>
              </div>
              <div class="col-md-10">{{ item.url }}</div>
              <div class="col-md-2 d-flex pr-0">
                Error <span class="ml-auto">:</span>
              </div>
              <div class="col-md-10">{{ item.error }}</div>
              <div class="col-md-2 d-flex pr-0">
                Type <span class="ml-auto">:</span>
              </div>
              <div class="col-md-10">{{ item.type }}</div>
              <div class="col-md-2 d-flex pr-0">
                Line <span class="ml-auto">:</span>
              </div>
              <div class="col-md-10">{{ item.line }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import loader from "../loader.vue";
import helper from "@/helper/helper";

export default {
  name: "detailForumLogs",
  components: {
    BModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    loader,
  },
  props: {
    id: String,
  },
  data() {
    return {
      is_loading: true,
      page: this.$route.query.page ? this.$route.query.page : 1,
      items: this.$route.query.items ? this.$route.query.items : 10,
      iscopied: false,
      id_copied: null,
      data: null,
    };
  },
  methods: {
    getData(id) {
      this.is_loading = true;
      let params = {
        page: this.page,
        items: this.items,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "forum/log/" + id + "?" + query;
      useJwt.axiosIns
        .get(api)
        .then((res) => {
          this.data = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    parseDate(date) {
      return helper.dateParser(date);
    },
    dateConvertUTC7(date) {
      return helper.dateConvertUTC7(date);
    },
    copyURL(url, id) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.iscopied = true;
          this.id_copied = id;
          setTimeout(() => {
            this.iscopied = false;
          }, 2000);
        })
        .catch((error) => {
          console.error("Gagal menyalin teks ke clipboard: ", error);
        });
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>