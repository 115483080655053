<template>
  <div id="forum-logs-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-chat-text"></i>
          <span style="margin-left: 5px"> Forum Logs </span>
        </b-card-title>
        <!-- filter component -->
        <div
          class="d-flex flex-wrap gap-5px justify-content-end align-items-center"
        >
          <!-- date filter -->
          <dateRangePicker
            :from_date="filter_data.from_date"
            :to_date="filter_data.to_date"
            @update-value="dateFilterChanged"
          />
          <!-- keyword filter -->
          <b-input-group class="input-group-merge" style="width: 20rem">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter_data.key"
              placeholder="Masukkan kata kunci..."
            />
          </b-input-group>
        </div>
      </b-card-header>
      <!-- loader component -->
      <loader v-if="is_loading" />
      <!-- dataTable -->
      <dataTable
        v-else
        :data="table_data"
        :is_row_clicked="true"
        :is_pagination="false"
        :viewDetail="viewDetail"
      />
      <paginationInfo
        :page="pagination.page"
        :items="pagination.items"
        :count="pagination.count"
        @update-value="paginationChanged"
      />
    </b-card>
    <!-- detail forum log modal component -->
    <detailForumLogs ref="detail_forum" />
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import useJwt from "@/auth/jwt/useJwt";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import dataTable from "@/views/components/dataTable.vue";
import paginationInfo from "../components/paginationInfo.vue";
import loader from "@/views/components/loader.vue";
import dateRangePicker from "@/views/components/dateRangePicker.vue";
import detailForumLogs from "../components/forum/detailForumLogs.vue";

export default {
  name: "ForumLogs",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    paginationInfo,
    dataTable,
    loader,
    dateRangePicker,
    detailForumLogs,
  },
  data() {
    return {
      is_loading: true,
      filter_data: {
        from_date:
          this.$route?.query?.from_date ||
          moment(new Date())
            .subtract(7, "days")
            .format("YYYY-MM-DD 00:00:00"),
        to_date:
          this.$route?.query?.to_date ||
          moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        tier: null,
        key: "",
      },
      pagination: {
        page: 1,
        items: 10,
        count: 0,
      },
      table_data: {
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "table-header text-center",
            tdClass: "text-center",
          },
          {
            key: "scraper_name",
            label: "nama scraper",
            thClass: "table-header text-left",
            tdClass: "text-left",
          },
          {
            key: "error_message",
            label: "pesan error",
            thClass: "table-header text-left",
            tdClass: "text-left",
          },
          {
            key: "start",
            label: "tanggal berjalan",
            sortable: true,
            thClass: "table-header text-center",
            tdClass: "text-center",
          },
          {
            key: "end",
            label: "tanggal terhenti",
            sortable: true,
            thClass: "table-header text-center",
            tdClass: "text-center",
          },
          {
            key: "status",
            label: "status",
            thClass: "table-header text-center",
            tdClass: "text-center",
          },
          {
            key: "count",
            label: "jumlah berita",
            thClass: "table-header text-center",
            tdClass: "text-center",
          },
          {
            key: "duration",
            label: "durasi",
            thClass: "table-header text-center",
            tdClass: "text-center",
          },
        ],
        items: [],
      },
      cancel_token: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "filter_data.key"() {
      if (
        this.filter_data.key.trim().length >= 2 ||
        this.filter_data.key.trim().length == 0
      ) {
        this.getData();
      }
    },
  },
  methods: {
    dateFilterChanged(data) {
      this.filter_data.from_date = data.from_date;
      this.filter_data.to_date = data.to_date;
      this.getData();
    },
    paginationChanged(data) {
      this.pagination = data;
      this.getData();
    },
    getData() {
      this.is_loading = true;
      if (this.cancel_token) {
        this.cancel_token.cancel("ERR_CANCELED");
      }
      this.cancel_token = axios.CancelToken.source();

      let params = {
        from_date: this.filter_data.from_date,
        to_date: this.filter_data.to_date,
        page: this.pagination.page,
        items: this.pagination.items,
        ...(this.filter_data.key ? { name: this.filter_data.key } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "forum/log?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancel_token.token })
        .then((res) => {
          this.cancel_token.value = null;
          this.table_data.items = res.data?.log_scraper || [];
          this.pagination.count = res.data?.page_info[0].total || 0;
        })
        .catch((err) => {
          if (err.message != "ERR_CANCELED") {
            this.cancel_token.value = null;
          } else {
            this.cancel_token.value = "CANCELED";
          }
        })
        .finally(() => {
          if (!this.cancel_token.value) {
            this.is_loading = false;
          }
        });
    },
    viewDetail(id) {
      let item = this.table_data.items.find((el) => el._id == id);
      if (item.status == "Error" || item.status == "error") {
        this.$bvModal.show("modal-detail-forum");
        this.$refs.detail_forum.getData(id);
      }
    },
  },
};
</script>
